import React from "react"
import styled from 'styled-components'

import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../../../utils/api/auth"
import Axios from "axios"

const StyledLogin = styled.div`
  label {
    color: #fff;
  }
`

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    Axios.post('https://api.leadmirror.com/api/user/login/', this.state)
    .then(function (response) {
      console.log(response.data);
      window.localStorage.setItem("user", JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
    // handleLogin(this.state)
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/app/profile`)
    }

    return (
      <StyledLogin>
        <h1>Log in</h1>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
            navigate(`/app/profile`)
          }}
        >
          <label>
            Username
            <input type="text" name="username" onChange={this.handleUpdate} />
          </label>
          <label>
            Password
            <input
              type="password"
              name="password"
              onChange={this.handleUpdate}
            />
          </label>
          <input type="submit" value="Log In" />
        </form>
      </StyledLogin>
    )
  }
}

export default Login