import React from 'react'
import { Router } from "@reach/router"
import Layout from '../components/resuable/layout/layout'
import Profile from '../components/page/a2i-world-app/profile/profile'
import Login from '../components/page/a2i-world-app/login/login'
import Default from '../components/page/a2i-world-app/default/default'
import PrivateRoute from '../components/page/a2i-world-app/router/Private'

const A2iWorldApp = () => {
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/app/profile" component={Profile}/>
        <Login path="/app/login" />
        <Default path="/app" />
      </Router>
    </Layout>
  )
}

export default A2iWorldApp
